.icon-add:before { content: "\ea01"; }
.icon-back:before { content: "\ea02"; }
.icon-close:before { content: "\ea03"; }
.icon-discord:before { content: "\ea04"; }
.icon-facebook:before { content: "\ea05"; }
.icon-forze:before { content: "\ea06"; }
.icon-gestion-bos:before { content: "\ea07"; }
.icon-gmail:before { content: "\ea08"; }
.icon-google-sheets:before { content: "\ea09"; }
.icon-google:before { content: "\ea0a"; }
.icon-instagram:before { content: "\ea0b"; }
.icon-linkedin:before { content: "\ea0c"; }
.icon-mastodon:before { content: "\ea0d"; }
.icon-mercado-libre:before { content: "\ea0e"; }
.icon-messenger:before { content: "\ea0f"; }
.icon-minus:before { content: "\ea10"; }
.icon-next:before { content: "\ea11"; }
.icon-nubila:before { content: "\ea12"; }
.icon-omnitec:before { content: "\ea13"; }
.icon-openai:before { content: "\ea14"; }
.icon-outlook:before { content: "\ea15"; }
.icon-pinterest:before { content: "\ea16"; }
.icon-plus:before { content: "\ea17"; }
.icon-product:before { content: "\ea18"; }
.icon-quora:before { content: "\ea19"; }
.icon-reddit:before { content: "\ea1a"; }
.icon-search:before { content: "\ea1b"; }
.icon-settings:before { content: "\ea1c"; }
.icon-skype:before { content: "\ea1d"; }
.icon-sms:before { content: "\ea1e"; }
.icon-snapchat:before { content: "\ea1f"; }
.icon-telegram:before { content: "\ea20"; }
.icon-tiendanube:before { content: "\ea21"; }
.icon-tiktok:before { content: "\ea22"; }
.icon-tumblr:before { content: "\ea23"; }
.icon-twitch:before { content: "\ea24"; }
.icon-twitter:before { content: "\ea25"; }
.icon-up:before { content: "\ea26"; }
.icon-wechat:before { content: "\ea27"; }
.icon-whatsapp:before { content: "\ea28"; }
.icon-youtube:before { content: "\ea29"; }

